<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="100%">
          <v-card-title class="text-h4">Inhaltliche Verantwortung</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12"> Anja Mannstedt </v-col>
              <v-col cols="12"> Normannenweg 9 </v-col>
              <v-col cols="12"> 25996 Wenningstedt-Braderup </v-col>
              <v-col cols="12"> Tel.: 04651/29486 </v-col>
              <v-col cols="12">
                <a class="accent--text" href="mailto:fam-mannstedt@t-online.de">
                  fam-mannstedt@t-online.de
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card height="100%">
          <v-card-title class="text-h4">Webdesign und Umsetzung</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12"> Kim Christian Mannstedt </v-col>
              <v-col cols="12"> Königsweg 27 </v-col>
              <v-col cols="12"> 24103 Kiel </v-col>
              <v-col cols="12">
                <a
                  class="accent--text"
                  href="mailto:k.mannstedt+hauskim@gmail.com"
                >
                  k.mannstedt+hauskim@gmail.com
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h4">Haftungshinweis</v-card-title>
          <v-card-text>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich.
            <br />
            <br />
            Die Rechte an den auf dieser Webseite gezeigten Inhalten liegen bei
            den Betreibern der Seite und dürfen ohne ihre Zustimmung nicht
            kopiert werden.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'impress'
}
</script>

<style lang="scss" scoped>
</style>
